<template>
  <div id="Home">
    <div class="block">
      <div class="banner">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(i,item) in swiper"  :key="item">
            <a :href="i.hrefurl">
              <img v-if="i.imgurl.indexOf('http') != '-1'" :alt="i.description" :src="Global.changeImg(i.imgurl)"/>
              <img v-else :alt="i.description" :src="Global.changeImg(i.imgurl)"/>
            </a>
          </van-swipe-item>
        </van-swipe>
      </div>

      <div class="select">
        <div @click.stop="changePath('/ArticlesList')"><div><img src="../assets/image/home/select1.png"/></div><span>保观文章</span></div>

        <!-- <div @click.stop="changePath('/Connections')"><div><img src="../assets/image/home/select2.png"/></div><span>人脉圈</span></div> -->
        <!-- '/ArticlesList?Sup=1')  监管 -->
        <!-- <div @click.stop="changePath('/Information')"><div><img src="../assets/image/home/select8.png"/></div><span>资料库</span></div> -->
        <!-- <div @click.stop="changePath('/FindOut')"><div><img src="../assets/image/home/select3.png"/></div><span>问答</span></div> -->
        <!-- <div @click.stop="changePath('/CompanyList')"><div><img src="../assets/image/home/select4.png"/></div><span>公司库</span></div> -->
        <!-- <div @click.stop="changePath('/ActivityCourse?activeName=a')"  ><div><img src="../assets/image/home/select5.png"/></div><span>直播</span></div>
        <div @click.stop="changePath('/ActivityLine?activeName=c')"><div><img src="../assets/image/home/select6.png"/></div><span>线下活动</span></div> -->
        <div @click.stop="changePath('/ActivityCourse?activeName=b')" ><div><img src="../assets/image/home/select7.png"/></div><span>课程</span></div>
        <div @click.stop="changePath('/Information')"><div><img src="../assets/image/home/select8.png"/></div><span>资料库</span></div>
        <!-- <div @click.stop="changePath('/MyCollection')"><div><img src="../assets/image/home/select9.png"/></div><span>收藏</span></div> -->
        <div @click.stop="changePath('/Connections')"><div><img src="../assets/image/home/select2.png"/></div><span>人脉圈</span></div>
        
      </div>

      <!-- <div class="swiperDirection">
        <img src="../assets/image/home/littleTag.png"/>
        <van-swipe class="swiper-slide1" vertical :autoplay="3000" :show-indicators=false style="height:0.3rem" :touchable = false>
          <van-swipe-item v-for="(i,item) in quiz"  :key="item" @click="toQuestion(i.id)">
            <a ><div><img src="../assets/image/home/choicest.png"/><span>{{i.question}}</span></div><b class="right_arrow"></b></a>
          </van-swipe-item>
        </van-swipe>
      </div> -->
      

    </div>


    <div class="block">
      <div class="titleTop">今日推荐</div>
      <div class="recommended">
        <div v-if="doc[0]">
          <div class="recomFirst" @click="toLink(doc[0].doc_file_info.path,doc[0].doc_file_info.id)">
            <div>
              <p><span>最新资料在线查看</span></p>
              <p v-if="doc[0].doc_file_info">{{doc[0].doc_file_info.name}}</p>
            </div>
            <p>政策与解读 <van-icon name="eye-o" style="margin:0.02rem 0.05rem 0 0.13rem;font-size:0.14rem" /> {{doc[0].view_count}}</p>
          </div>
        </div>
        <div @click="toArticlLink">
          <div class="recomSecond">
            <div>
              <p><span>热门文章抢先看</span></p>
              <p>{{hotArticle.title}}</p>
            </div>
            <p>保观文章</p>
          </div>
        </div>
        <div @click.stop="changePath('/Activity?activeName=b')">
          <div class="recomThird">
            <div>
              <p><span>保观课程</span>已更新{{series.course_info_count}}课</p>
              <p>{{series.name}}</p>
            </div>
          </div>
        </div>
        <div @click.stop="changePath('/Activity?activeName=c')">
          <div class="recomForth">
            <div>
              <p><span>保观活动</span></p>
              <p>{{ activity ? activity.title : '报名参加和大咖交流' }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 打招呼 -->
    <div class="block">
      <div class="titleTop interests">你可能感兴趣的人<span @click="changePath('/Connections')">查看更多<b class="right_arrow"></b></span></div>
      <div class="familiar">
        <div v-for="(i,item) in interest" :key="item" @click="toIntroDetail(i.submit_id)">
          <img :src="i.user.headimgurl ? Global.changeImg(i.user.headimgurl) :'../assets/image/home/image1.png'" />
          <p>{{i.name}}</p>
          <p>{{i.company}}</p>
          <span @click.stop="toHello(i.submit_id)">打个招呼</span>
        </div>
      </div>
    </div>

    <div class="titleTop dynamicState block">热门动态<div>
      <span :class="{'active':type == 2}" @click="checkType(2)">最新</span>
      <span :class="{'active':type == 1}" @click="checkType(1)">推荐</span>
      </div>
    </div>
    <div class="block" style="margin-bottom:0;">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        class="hotMain"
      >
        <div v-for="(i,item) in dynamicList" :key="item">
          <div class="first">
            <div>
              <img style="border-radius: 50%;" src="../assets/image/home/image1.png" />
              <span>{{i.author}}</span>
              <img v-if="i.doc_file_info" src="../assets/image/home/type1.png" /><img v-else src="../assets/image/home/type2.png" />
            </div>
            <span v-if="!i.doc_file_info"  @click="toWLink(i.wx_url,i.id)">{{Global.fromatTimestamps(i.created_at)}}</span>
            <span v-else>{{i.publish_at}}</span>
          </div>
          <p class="second" v-if="!i.doc_file_info"  @click="toWLink(i.wx_url,i.id)"> {{i.title}}</p>
          <!-- <p class="third" v-if="!i.doc_file_info"  @click="toWLink(i.wx_url,i.id)"> {{i.title}}{{i.title}}</p> -->
          <div v-else class="fifth" @click="toLink(i.doc_file_info.path,i.doc_file_info.id)">
            <img v-if="i.doc_file_info.type == 1" src="../assets/image/home/doc_type_1.png" />
            <img v-else-if="i.doc_file_info.type == 2" src="../assets/image/home/doc_type_2.png" />
            <img v-else-if="i.doc_file_info.type == 3" src="../assets/image/home/doc_type_3.png" />
            <img v-else-if="i.doc_file_info.type == 4" src="../assets/image/home/doc_type_4.png" />
            <img v-else-if="i.doc_file_info.type == 5" src="../assets/image/home/doc_type_5.png" />
            <div>
              <p>{{i.doc_file_info.name}}</p>
              <p>{{i.doc_file_info.ext}} {{i.doc_file_info.size}}</p>
            </div>
          </div>
          <div class="forth">
            <div v-if="i.doc_file_info">{{i.category.name}}</div>
            <div v-else> <span v-for="(j,item) in  i.article_category" :key="item">{{j.name}}</span></div>
            <div>
              
              <span v-if="!i.doc_file_info"><van-icon name="eye-o" style="margin:0 0.05rem 0 0.13rem;font-size:0.16rem" />{{i.views}}</span>
              <span v-else><van-icon name="eye-o" style="margin:0 0.05rem 0 0.13rem;font-size:0.16rem" />{{i.view_count}}</span>
              <span v-if="i.doc_file_info" style="font-size:0.12rem">
                <van-icon @click.stop="noStar(i.id,item)" v-if="i.collect" name="star" style="margin:0.02rem 0.05rem 0 0.13rem;"  color="#E59813" />
                <van-icon @click.stop="toStar(i.id,item)" v-else name="star-o" style="margin:0.02rem 0.05rem 0 0.13rem"  color="#E59813" />
              </span>
            </div>
          </div>
        </div>
      </van-list>
      
    </div>

    <van-popup v-model="show" style="background:none">
      <img class="main" src="img/kf3.png" />
      <div class="close">
        <span @click="show = false">
          <van-icon color="#fff" name="cross" style="font-size:0.2rem" />
        </span>
      </div>
    </van-popup>
  </div>
</template>

<script>
import start from '../assets/image/findOut/start.png'
import startn from '../assets/image/findOut/startn.png'
export default {
  name: 'Home',
  data () {
    return {
      show: false,
      swiper:[],
      quiz:[],
      doc:[],
      series:[],//开启一天新思路
      hotArticle:{},
      interest:[],
      type:2,
      dynamicList:[],
      loading:false,
      finished:false,
      start:1,//页码
      isVip:'',
      activity: {},
      hotArticleType:'',
      isActivate: ''
    }
  },
  created(){
    this.Global.getShareConfig({
      title: '保观大咖会  | 高效的资讯获取',
      desc: '幸运的是，今天的我们不用担心过于专注而被野兽吃掉；不幸的是，知识爆炸的今天我们必须学会过滤信息。'
    },this)
  },
  mounted(){
    // 根据参数选择是否展示弹出框
    if (this.$route.query.showQrCode == 1) {
      this.show = true
    }

    // 体验会员活动
    if (
      this.$route.query.userActivity == 'bdk:20211020:experience:1-month' 
      && this.$route.query.userActivitySign == 'dff30b4897d642fa642841cbf71453dc'
    ) {
      this.axios({
        method: 'GET',
        url: '/user/getUserStatus',
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          if (res.data.data.status == 8 && res.data.data.isActivate == 0) {
            this.$dialog.confirm({
              title: '用户福利',
              message: "恭喜您获得一个月体验会员资格！\n完善个人信息即可激活会员",
              confirmButtonText: '去填写',
              cancelButtonText: '狠心放弃',
              cancelButtonColor: '#ee0a24'
            }).then(() => {
              this.$router.push('/EditMessage')
            });
          }
        }
      })
    }

    this.axios({
      method: 'GET',
      url: '/homePage?count=5',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res) => {
      if(res.data.code == 0){
        this.isVip = res.data.data.status  //1:非会员  2:会员 3:会员过期 4:会员已锁定
        this.swiper = res.data.data.swiper
        this.quiz = res.data.data.quiz
        this.doc = res.data.data.doc
        this.series = res.data.data.series
        this.hotArticleType = res.data.data.hotArticleType
        this.hotArticle = res.data.data.hotArticle
        this.interest = res.data.data.interest
        this.activity = res.data.data.activity
        this.isActivate = res.data.data.isActivate
      }
    })
    this.axios({
      method: 'GET',
      url: '/homePage/dynamic?type=2&start=1',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res) => {
      if(res.data.code == 0){
        this.dynamicList = res.data.data
        this.start++
      }
    })
  },
  methods:{
    toStar(docId,item){
      this.axios({
        method: 'PATCH',
        url: '/doc/collectFile',
        data:{
          status:1,
          docId:docId
        },
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.dynamicList[item].collect = true
          this.$toast({
            message: '收藏成功',
            icon: start,
          })
        }
      })
    },
    noStar(docId,item){
      this.axios({
          method: 'PATCH',
          url: '/doc/collectFile?sort='+this.sort+'&docId='+docId,
          data:{
            status:0,
            docId:docId
          },
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.dynamicList[item].collect = false
            this.$toast({
              message: '取消收藏',
              icon: startn,
            })
          }
        })
    },  
    toQuestion(id){
      this.$router.push({path:'/QuestionDetail?id='+id})
    },
    // 打招呼
    toHello(id){
      this.axios({
        method: 'POST',
        url: '/recognition/greetMaster',
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        data:{
          masterId:id
        }
      }).then((res) => {
        if(res.data.code == 0){
          this.$toast(res.data.data.msg)          
        }
      })
    },
    // 跳转大咖详情
    toIntroDetail(id){
      this.axios({
        method: 'PATCH',
        url: '/master/alwaysVisitAdd',
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        data:{
          masterId:id
        }
      }).then((res) => {
        if(res.data.code == 0){
          this.$router.push({name:'Introduction',query: {id:id}})
          
        }
      })
    },
    // 跳转页面
    changePath(path){
      this.$router.push(path)
    },
    // 热门文章跳转
    toArticlLink(){
      if(this.hotArticleType == 1){
        window.location.href = this.hotArticle.wx_url
      }else{
        this.$router.push('/ArticleDetail?articleId='+this.hotArticle.id)
      }
    },
    // 跳转文件连接
    toLink(link,id){
      if(this.isVip == 2 || (this.isVip == 8 && this.isActivate == 1)){
        this.axios({
          method: 'PATCH',
          url: '/doc/addDocViewCount/'+id,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then(() => {
          window.location.href = link
        }) 
      }else{
        let msg = '';

        if (this.isVip == 8) {
          msg = '请先激活会员'
        } else {
          msg = '请先购买或激活会员'
        }
        this.$toast(msg)
        var _this = this;
        setTimeout(function(){
          if (_this.isVip == 8) {
            _this.$router.push('/EditMessage')
          } else {
            _this.$router.push('/MemberProduct')
          }
          
        },2000)
      }
    },
    toWLink(url,id){
      // if(this.isVip == 2){
        this.axios({
          method: 'PATCH',
          url: '/article/addArticleViews/'+id,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then(() => {
          window.location.href = url
        })
      // }else{
      //   this.$toast('请先购买会员')
      //   var _this = this;
      //   setTimeout(function(){
      //     _this.$router.push('/MemberProduct')
      //   },2000)
      // }
    },
    checkType(a){
      this.type = a
      this.start = 1
      this.finished = false
      this.axios({
        method: 'GET',
        url: '/homePage/dynamic?type='+this.type+'&start='+this.start,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.dynamicList = []
          this.dynamicList = res.data.data
          this.start++
        }
      })
    },
    onLoad(){
      if(this.start !=1){
        if(this.start > 20){
          this.$router.push('/ArticlesList')
        }else{
          this.axios({
            method: 'GET',
            url: '/homePage/dynamic?type='+this.type+'&start='+this.start,
            headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
          }).then((res) => {
            if(res.data.code == 0){
              this.loading = false
              if(res.data.data.length){
                this.dynamicList.push(...res.data.data)
                this.start++
              }else{
                this.start = 1
              }
              if(!res.data.data.length || !res.data.data){
                this.finished = true
              } 
            }else{
              this.loading = false;
              this.finished = true
            }
          })
        }
      }else{
        this.loading = false;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="less">
#Home{
  .main{
    width: 2.63rem;
  }
  .close{
    text-align: center;
    >span{
      width: 0.35rem;
      height: 0.35rem;
      background: #6e6f72;
      border-radius: 50%;
      margin-top: 0.2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 0.2rem;
    }
  }
  .hotMain{
    padding: 0 0.15rem;
    >div{
      padding: 0.18rem 0;
      border-bottom: .1px solid #5C5D6E61;
      .fifth{
        background: #3D404F;
        border-radius: 0.05rem;
        padding: 0.11rem 0.2rem;
        display: flex;
        justify-content: flex-start;
        margin: 0.15rem 0 0.12rem;
        >div{
          >p:last-child{
            color: #989AB1;
            font-size: 0.12rem;
          }
          >p:first-child{
            color: #F6F7FD;
            font-size: 0.14rem;
            margin-bottom: 0.05rem;
          }
        }
        >img{
          width: 0.3rem;
          margin-right: 0.16rem;
        }
      }
      .forth{
        >div:last-child{
          display: flex;
          >span{
            margin-left: 0.2rem;
            color: #727387;
            display: flex;
            align-items: center;
            font-size: 0.12rem;
          }
        }
        >div:first-child{
          color: #9091A3;
          padding: 0 0.05rem;
          background: #3D404F;
          border-radius: 0.02rem;
          font-size: 0.12rem;
        }
      }
      .third{
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #989AB1;
        font-size: 0.13rem;
        line-height: 0.17rem;
        margin-bottom: 0.15rem;
      }
      .second{
          color: #F6F7FD;
          font-size: 0.14rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin: 0.15rem 0 0.12rem;
          display: block;
      }
      .first{
        >div{
          color: #989AB1;
          font-size: 0.14rem;
          display: flex;
          align-items: center;
          >span{
            margin: 0 0.1rem 0 0.08rem;
          }
          >img{
            width: 0.27rem;
          }
        }
        >span{
          color: #727387;
          font-size: 0.12rem;
        }
      }
      >div{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .dynamicState{
    border-bottom: .1px solid #5C5D6E61;
    position: sticky;
    top: 0;
    margin-bottom: 0;
    z-index: 2;
    >div{
      .active{
        position: relative;
        color: #F6F7FD;
      }
      .active::after{
        position:absolute;
        content: '';
        width: 100%;
        height: 2px;
        background: #3890FF;
        left: 0;
        bottom: -0.05rem;
      }
      >span{
        margin-left: 0.18rem;
        font-size: 0.14rem;
        color: #9091A3;
      }
    }
  }
  .familiar{
    overflow-x: scroll;
    padding-left: 0.15rem;
    margin-bottom: 0.2rem;
    white-space: nowrap;
    >div{
      width: 1.39rem;
      // height: 1.84rem;
      padding-bottom: 0.2rem;
      border-radius: 0.05rem;
      margin-right: 0.15rem;
      text-align: center;
      display: inline-block;
      background:#3D404F;
      >img{
        display: inline-block;
        width: 0.57rem;
        height: 0.57rem;
        margin: 0.2rem 0 0.12rem;
        border-radius: 50%;
      }
      >p:nth-child(2){
        color: #FFFFFF;
        font-size: 0.14rem;
      }
      >p:nth-child(3){
        color: #9091A3;
        height: 0.16rem;
        font-size: 0.12rem;
        margin: 0.03rem 0 0.13rem;
        padding: 0 0.05rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      >span{
        display: inline-block;
        color: #9091A3;
        font-size: 0.13rem;
        height: 0.27rem;
        border-radius: 0.27rem;
        line-height: 0.27rem;
        color: #fff;
        background:#3890FF;
        padding: 0 0.11rem;
      }
    }
  }
  .interests>span{
    color: #9091A3;
    font-size: 0.14rem;
    display: flex;
    align-items: center;
    margin-right: 0.05rem;
  }
  .titleTop{
    padding: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFFFFF;
    font-size: 0.16rem;
    font-weight: 600;
  }
  .right_arrow{
    width: 0.06rem;
    height: 0.06rem;
    border-top: 1px solid #CFD0E0;
    border-right: 1px solid #CFD0E0;
    transform: rotate(45deg);
    margin-right: 0.01rem;
  }
  .recommended{
    color: #fff;    
    margin: 0 0.15rem 0.15rem;
    display: grid;
    grid-template-columns:1fr 1fr;
    grid-template-rows: 0.96rem 0.96rem 0.96rem;
    grid-row-gap: 0.12rem;
    grid-column-gap: 0.12rem;
    .recomForth{
      >div{
        >p:first-child{
          color: #FFF1F2;
          >span{
            margin-right: 0.04rem;
            color: #299BD6;
          }
        }
      }
      >p{
        color: #FFF1F2;
        font-size: 0.12rem;
        position:absolute;
        bottom: 0.1rem;
      }
    }
    .recomThird{
      >div{
        >p:first-child{
          color: #FFF1F2;
          >span{
            margin-right: 0.04rem;
            color: #DB4F5D;
          }
        }
      }
      >p{
        color: #FFF1F2;
        font-size: 0.12rem;
        position:absolute;
        bottom: 0.1rem;
      }
    }
    .recomSecond{
      >div{
        >p:first-child{
          color: #7F5430;
        }
        >p:last-child{
          padding-right: 0.5rem;
        }
      }
      >p{
        font-size: 0.12rem;
        color: #FFF1E5;
        position:absolute;
        bottom: 0.1rem;
      }
    }
    .recomFirst{
      padding-right: 0.15rem;
      >div{
        >p:first-child{
          color: #374255;
        }
        >p:last-child{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      >p{
        font-size: 0.12rem;
        color: #ABACC4;
        position:absolute;
        bottom: 0.1rem;
      }
    }
    >div:nth-child(1){
      grid-column-start: 1;
      grid-column-end: 3;
      background: url(../assets/image/home/recommended1.png) no-repeat;
      background-size: cover;
    }
    >div:nth-child(2){
      grid-row-start: 2;
      grid-row-end: 4;
      background: url(../assets/image/home/recommended21.png) no-repeat;
      background-size: cover;
    }
    >div:nth-child(3){
      background: url(../assets/image/home/recommended3.png) no-repeat;
      background-size: cover;
    }
    >div:nth-child(4){
      background: url(../assets/image/home/recommended4.png) no-repeat;
      background-size: cover;
    }
    >div{
      display: inline-block;
      border-radius: 0.05rem;
      >div{
        position: relative;
        padding: 0.14rem 0 0.15rem 0.15rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        box-sizing: border-box;
        >div{
          >p:first-child{
            margin-bottom: 0.1rem;
            display: flex;
            align-items: center;
            font-size: 0.12rem;
            font-weight: 600;
            >span{
              padding: 0.02rem 0.05rem;
              background:#FFFFFF;
              border-radius: 3px;
            }
          }
          >p:nth-child(2){
            color: #FFFFFF;
            font-size: 0.14rem;
          }
        }
        >p{
          margin-top: 0.1rem;
        }
      }
    }
  }

  .swiperDirection{
    display: flex;
    align-items: center;
    height: 0.15rem;
    padding: 0.08rem 0.15rem;
    background: #3D404F;
    border-radius: 0.3rem;
    margin: 0 0.15rem 0.15rem;
    >img{
      width: 0.61rem;
      height: 0.15rem;
      margin-right: 0.1rem;
    }
    >div{
      flex: 1;
    }
    .swiper-slide1{
      width: 100%;
      .van-swipe-item{
        line-height: 0.3rem;
      }
      a{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        div{
          display: flex;
          align-items: center;
          color: #FFFFFF;
          font-size: 0.14rem;
          overflow: hidden;
          img{
            height: 0.13rem;
            margin-right: 0.07rem;
          }
          span{
            white-space: nowrap;
            text-overflow: ellipsis;
            flex: 1;
            overflow: hidden;
            font-size: 0.12rem;
          }
        }
        
      }
    }
  }
  .select{
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    font-size: 0.14rem;
    color: #F6F7FD;
    padding: 0.2rem 0.15rem 0;
    >div:nth-child(1)>div>img,>div:nth-child(7)>div>img,>div:nth-child(8)>div>img,>div:nth-child(2)>div>img{
      width: 0.36rem;
    }
    >div:nth-child(3)>div>img,div:nth-child(5)>div>img{
      width: 0.4rem;
    }
    >div:nth-child(4)>div>img,>div:nth-child(6)>div>img{
      width: 0.36rem;
    }
    >div{
      margin-bottom: 0.2rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      >div{
        height: 0.32rem;
        display: flex;
        align-items: center;
      }
      >span{
        margin-top: 0.12rem;
      }
    }
  }
  .banner{
    padding: 0.15rem 0.15rem 0.1rem;
  }
  >div.block{
    margin-bottom: 0.1rem;
    background: #23252E;
    overflow: hidden;
  }
}
.my-swipe img{
  min-height: 1.25rem;
  width: 100%;
}
.swiper-pagination{
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
</style>